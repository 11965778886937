.v-left .img-container{
    border: 8px solid rgba(232, 232, 232, 0.95);
}

.v-container>div{
    flex: 1;
}

.v-right{
    gap: 0.5rem;
}

.accordion{
    margin-top: 2rem;
    border: none;
}

.accordionItem{
    background: white;
    border: 0.8px solid rgba(128, 128, 128, 0.143);
    overflow: hidden;
    margin-bottom: 20px;
    border-radius: 8px;
}

.accordionItem.expanded{
    box-shadow: var(--shadow);
    border-radius: 5px;
}

.accordionButton{
    background: white;
    justify-content: space-between;
    width: 100%;
    padding: 1rem;
    cursor: pointer;
}

.icon{
    padding: 10px;
    background: #eeefff;
    border-radius: 4px;
}

.icon svg{
    fill: var(--blue);
}

.accordionButton .primaryText{
    font-size: 1.1rem;
}

/* responsive */
@media (max-width: 1024px) {
    .v-container{
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .accordionButton .primaryText{
        font-size: 0.8rem;
    }
    
}