.app{
  position: relative;
  overflow-x: clip;
}

.app>:nth-child(1){
  background-color: var(--black);
  position: relative;
}

.white-gradient{
  position: absolute;
  width: 20rem;
  height: 20rem;
  background: rgba(255, 255, 255, 0.522);
  filter: blur(100px);
  border-radius: 100px;
}

.img-container {
  width: 30rem;
  height: 35rem;
  overflow: hidden;
  border-radius: 15rem 15rem 0 0;
  border: 8px solid rgba(255, 255, 255, 0.13);
}

.img-container>img {
  width: 100%;
  height: 100%;
}
